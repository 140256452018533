import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import AddButton from '../AddButton';

const useStyles = makeStyles({
  input: {
    display: 'none',
  },
});

const handleUploadClick = (event) => {
  const file = event.target.files[0];
  console.log(file);
};

const FileInput = () => {
  const classes = useStyles();

  return (
    <label htmlFor="contained-button-file">
      <AddButton />
      <input
        type="file"
        id="contained-button-file"
        className={classes.input}
        onChange={handleUploadClick}
      />
    </label>
  );
};

export default FileInput;
