import React from 'react';
import { CardContent, CardMedia, Typography } from '@mui/material';

import CardTemplate from '../CardTemplate';

const NeedCard = () => (
  <CardTemplate style={{ height: 235 }} pathTo="/NeedPage">
    <CardMedia
      component="img"
      src="https://sayapp.company/files/3-child/needs/1841-need/1841-image_a6e8ddbf330c46c8816484951f3526dc1841.png"
      title="need name"
    />
    <CardContent>
      <Typography variant="body1" align="center">
        ابزار کمک آموزشی
      </Typography>
    </CardContent>
  </CardTemplate>
);

export default NeedCard;
