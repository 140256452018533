/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Paper,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  TextField,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterJalali from '@date-io/date-fns-jalali';
import { useSelector, useDispatch } from 'react-redux';

import FileInput from '../Inputs/FileInput';
import DefaultButton from '../DefaultButton';
import { fetchNgos } from '../../actions/ngoAction';

// FIXME: must conditionally change to AdapterDateFns based on language
const DateAdapter = AdapterJalali;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles({
  root: {
    padding: 12,
    width: '100%',
  },
});

const genders = [
  {
    value: '',
    label: '',
  },
  {
    value: true,
    label: 'Boy',
  },
  {
    value: false,
    label: 'Girl',
  },
];

const educationStatuses = [
  {
    value: '',
    label: '',
  },
  {
    value: '-3',
    label: 'بازمانده از تحصیل',
  },
  {
    value: '-2',
    label: 'مدرسه نمی‌رود',
  },
  {
    value: '-1',
    label: 'مهد کودک',
  },
  {
    value: '0',
    label: 'پیش‌دبستانی',
  },
  {
    value: '1',
    label: 'کلاس اول',
  },
  {
    value: '2',
    label: 'کلاس دوم',
  },
  {
    value: '3',
    label: 'کلاس سوم',
  },
  {
    value: '4',
    label: 'کلاس چهارم',
  },
  {
    value: '5',
    label: 'کلاس پنجم',
  },
  {
    value: '6',
    label: 'کلاس ششم',
  },
  {
    value: '7',
    label: 'کلاس هفتم',
  },
  {
    value: '8',
    label: 'کلاس هشتم',
  },
  {
    value: '9',
    label: 'کلاس نهم',
  },
  {
    value: '10',
    label: 'کلاس دهم',
  },
  {
    value: '11',
    label: 'کلاس یازدهم',
  },
  {
    value: '12',
    label: 'کلاس دوازدهم',
  },
  {
    value: '13',
    label: 'دانشجو',
  },
];

const AddChild = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [gender, setGender] = useState('');
  const [education, setEducation] = useState('');
  const [birthdate, setBirthdate] = useState(null);
  const [ngo, setNgo] = useState('');

  const dispatch = useDispatch();
  const { checkResult, loading, success } = useSelector(
    (state) => state.fetchNgos
  );
  const ngos = success ? Object.assign([], Object.values(checkResult)) : [];

  useEffect(() => {
    dispatch(fetchNgos());
  }, [dispatch]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };
  const handleChangeEducation = (event) => {
    setEducation(event.target.value);
  };
  const handleChangeBirthdate = (newValue) => {
    setBirthdate(newValue);
  };
  const handleChangeNgo = (event) => {
    setNgo(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={3}>
        <Grid container item justifyContent="space-between">
          <Typography variant="h2">Add child</Typography>
        </Grid>

        <Grid container item spacing={2}>
          <Paper sx={{ width: '100%', p: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                variant="scrollable"
                aria-label="add child"
              >
                <Tab label="Basic Data" {...a11yProps(0)} />
                <Tab label="Living Data" {...a11yProps(1)} />
                <Tab label="Living Situation" {...a11yProps(2)} />
                <Tab label="Child's Recorded Voice" {...a11yProps(3)} />
                <Tab label="Child's Short Story" {...a11yProps(4)} />
                <Tab
                  label="Child's Edited Voice and Avatars"
                  {...a11yProps(5)}
                />
              </Tabs>
            </Box>
            {/* Basic Data */}
            <TabPanel value={value} index={0}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '312px' },
                }}
              >
                <TextField
                  select
                  id="ngo"
                  label="ngo"
                  variant="filled"
                  value={ngo}
                  onChange={handleChangeNgo}
                >
                  {ngos.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  id="sw"
                  label="social worker"
                  variant="filled"
                  value=""
                  // onChange=""
                >
                  {/* sw */}
                </TextField>
                <TextField id="name" label="name" variant="filled" />
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    mask="____/__/__"
                    label="birthdate"
                    value={birthdate}
                    onChange={handleChangeBirthdate}
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" id="birthdate" />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  select
                  id="education"
                  label="education"
                  variant="filled"
                  value={education}
                  onChange={handleChangeEducation}
                >
                  {educationStatuses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="birth-city"
                  label="birth-city"
                  variant="filled"
                />
                <TextField
                  select
                  id="sex"
                  label="sex"
                  variant="filled"
                  value={gender}
                  onChange={handleChangeGender}
                >
                  {genders.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </TabPanel>
            {/* Living Data */}
            <TabPanel value={value} index={1}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '312px' },
                }}
                flex
              >
                <TextField
                  id="living-with"
                  label="living-with"
                  variant="filled"
                />
                <TextField
                  id="family-count"
                  label="family-count"
                  variant="filled"
                />
                <TextField
                  id="phone-number"
                  label="phone-number"
                  variant="filled"
                  type="number"
                />
                <TextField
                  id="address"
                  label="address"
                  variant="filled"
                  multiline
                  rows={3}
                />
              </Box>
            </TabPanel>
            {/* Living Situation */}
            <TabPanel value={value} index={2}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
              >
                <TextField
                  id="real-story"
                  label="real-story"
                  variant="filled"
                  multiline
                  rows={4}
                />
              </Box>
            </TabPanel>
            {/* Child's Recorded Voice */}
            <TabPanel value={value} index={3}>
              <Card sx={{ textAlign: 'center' }}>
                <FileInput />
                <CardContent>Child's Recorded Voice</CardContent>
              </Card>
            </TabPanel>
            {/* Child's Short Story */}
            <TabPanel value={value} index={4}>
              <Box component="form">
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      id="summary-story-fa"
                      label="summary-story-fa"
                      variant="filled"
                      multiline
                      rows={3}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      id="summary-story-en"
                      label="summary-story-en"
                      variant="filled"
                      multiline
                      rows={3}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      id="story-fa"
                      label="story-fa"
                      variant="filled"
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      id="story-en"
                      label="story-en"
                      variant="filled"
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            {/* Child's avatars and edited voice */}
            <TabPanel value={value} index={5}>
              <Grid container spacing={2}>
                <Grid item lg={3} sm={6} xs={12}>
                  <Card sx={{ textAlign: 'center' }}>
                    <FileInput />
                    <CardContent>Child's Avatar</CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <Card sx={{ textAlign: 'center' }}>
                    <FileInput />
                    <CardContent>Child's Slept Avatar</CardContent>
                  </Card>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <Card sx={{ textAlign: 'center' }}>
                    <FileInput />
                    <CardContent>Child's Voice</CardContent>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>
            <DefaultButton text="Next" size="small" style={{ float: 'left' }} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddChild;
