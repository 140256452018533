import {
  FETCH_NGOS_REQUEST,
  FETCH_NGOS_SUCCESS,
  FETCH_NGOS_FAIL,
} from '../constants/ngoConstants';

export const fetchNgosReducer = (state = { checkResult: {} }, action) => {
  switch (action.type) {
    case FETCH_NGOS_REQUEST:
      return { loading: true, ...state };
    case FETCH_NGOS_SUCCESS:
      return { loading: false, success: true, checkResult: action.payload };
    case FETCH_NGOS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
