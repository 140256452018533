import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Grid, Container, CssBaseline } from '@mui/material';

import Splash from './pages/Splash';
import Intro from './pages/Intro';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Children from './pages/Children';
import AddChild from './components/Child/AddChild';
import ChildPage from './components/Child/ChildPage';
import Needs from './pages/Needs';
import AddNeed from './components/Need/AddNeed';
import NeedPage from './components/Need/NeedPage';
import TopBar from './components/TopBar';
import Menu from './components/Menu';

const App = () => (
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline />
      <TopBar />
      <Container maxWidth="xl">
        <div style={{ padding: 8 }} dir="rtl">
          <Grid container spacing={2}>
            <Grid item>
              <Menu />
            </Grid>
            <Grid item container sx={{ flex: 1 }}>
              <Switch>
                <Route exact path="/" component={Splash} />
                <Route exact path="/intro" component={Intro} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/children" component={Children} />
                <Route exact path="/AddChild" component={AddChild} />
                <Route exact path="/ChildPage" component={ChildPage} />
                <Route exact path="/needs" component={Needs} />
                <Route exact path="/AddNeed" component={AddNeed} />
                <Route exact path="/NeedPage" component={NeedPage} />
              </Switch>
            </Grid>
          </Grid>
        </div>
      </Container>
    </BrowserRouter>
  </React.StrictMode>
);
export default App;
