import { combineReducers } from '@reduxjs/toolkit';
import {
  checkContactReducer,
  checkUserNameReducer,
  userVerifyReducer,
  codeVerifyReducer,
} from './userReducer';
import { fetchNgosReducer } from './ngoReducer';

export default combineReducers({
  checkContact: checkContactReducer,
  checkUserName: checkUserNameReducer,
  verifyInfo: userVerifyReducer,
  verifyCode: codeVerifyReducer,
  fetchNgos: fetchNgosReducer,
});
