import React from 'react';
import { CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import CardTemplate from './CardTemplate';
import AddButton from './AddButton';

const AddCardTemplate = ({ height, pathTo }) => (
  <CardTemplate
    style={{ backgroundColor: '#295960', color: '#ffffff', height }}
    pathTo={pathTo}
  >
    <AddButton />
    <CardContent>
      <Typography variant="body1" align="center">
        Add
      </Typography>
    </CardContent>
  </CardTemplate>
);

AddCardTemplate.propTypes = {
  height: PropTypes.number,
  pathTo: PropTypes.string,
};

export default AddCardTemplate;
