import { createTheme } from '@mui/material/styles';
import { faIR, enUS } from '@mui/material/locale';

const theTheme = createTheme(
  {
    direction: 'rtl',
    fontFamily: 'iranyekan',

    palette: {
      orange: {
        light: '#fcb664',
        dark: '#df8537',
      },
      background: {
        default: '#f7f7f7', // CSSBaseline is needed for this
      },
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#fcb664',
        // dark: will be calculated from palette.primary.main,
        dark: '#df8537',
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#bfeeff',
        main: '#6be2fe',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color"s luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      // tonalOffset: 0.2,
    },

    typography: {
      fontFamily: '"iranyekan", "roboto"',
      h1: {
        fontSize: 24,
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 24,
      },
    },

    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: '4px 4px 20px rgba(196, 196, 196, 0.73)',
            borderRadius: 10,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: '4px 4px 20px rgba(196, 196, 196, 0.73)',
            borderRadius: 10,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          img: {
            width: 120,
            height: 120,
            marginTop: 20,
            borderRadius: '50%',
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#f1f1f1',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: '4px 4px 20px rgba(196, 196, 196, 0.73)',
            borderRadius: 10,
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            boxShadow: '4px 4px 20px rgba(196, 196, 196, 0.73)',
            borderRadius: 10,
            '&.Mui-focused': {},
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            right: 0,
            left: 'auto',
            transformOrigin: 'top right',
            transform: 'translate(-12px, 16px) scale(1)',
            '&.Mui-focused': {
              transform: 'translate(-12px, 7px) scale(.75)',
            },
            '&.MuiInputLabel-shrink': {
              transform: 'translate(-12px, 7px) scale(.75)',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            left: 7,
            right: 'auto',
          },
          filled: {
            borderRadius: 10,
          },
        },
      },
    },
  },
  faIR
);

export default theTheme;
