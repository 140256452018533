import React from 'react';
import MuiButton from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

const Button = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.orange.light,
    color: '#ffffff',
    fontSize: 18,
    '&:hover': {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  sizeSmall: {
    width: 180,
    height: 39,
    borderRadius: 5,
  },
  sizeLarge: {
    width: 157,
    height: 83,
  },
}))(MuiButton);

const DefaultButton = ({ text, size, style }) => (
  <Button size={size} sx={style} disableFocusRipple>
    {text}
  </Button>
);

DefaultButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  style: PropTypes.object,
};

export default DefaultButton;
