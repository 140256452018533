import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardActionArea, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    width: 180,
  },
  cardActionArea: {
    height: '100%',
  },
});

const CardTemplate = ({ children, style, pathTo }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} sx={style}>
      <CardActionArea
        className={classes.cardActionArea}
        component={Link}
        to={pathTo || '#'}
      >
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          {children}
        </Grid>
      </CardActionArea>
    </Card>
  );
};

CardTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  pathTo: PropTypes.string,
};

export default CardTemplate;
