import React from 'react';
import { Link } from 'react-router-dom';
import { MenuList, Typography, Box } from '@mui/material';
import MuiMenuItem from '@mui/material/MenuItem';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import DefaultButton from './DefaultButton';

const useStyles = makeStyles({
  root: {
    height: '80vh',
    borderLeft: '1px solid rgba(87, 84, 84, 0.42)',
  },
});

const MenuItem = withStyles({
  root: {
    justifyContent: 'flex-start',
    paddingTop: 16,
    paddingBottom: 16,
  },
})(MuiMenuItem);

const Menu = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      px={1}
      className={classes.root}
    >
      <MenuList>
        <MenuItem component={Link} to="/children" selected>
          <PersonRoundedIcon fontSize="large" htmlColor="#9F9999" />
          <Typography>کودکان</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/needs">
          <PersonRoundedIcon fontSize="large" htmlColor="#9F9999" />
          <Typography>نیازها</Typography>
        </MenuItem>
        <MenuItem component={Link} to="#">
          <PersonRoundedIcon fontSize="large" htmlColor="#9F9999" />
          <Typography>گزارش‌ها</Typography>
        </MenuItem>
        <MenuItem component={Link} to="#">
          <PersonRoundedIcon fontSize="large" htmlColor="#9F9999" />
          <Typography>مددکاران</Typography>
        </MenuItem>
        <MenuItem component={Link} to="#">
          <PersonRoundedIcon fontSize="large" htmlColor="#9F9999" />
          <Typography>تنظیمات</Typography>
        </MenuItem>
      </MenuList>
      <DefaultButton text="اعلانات" size="large" />
    </Box>
  );
};

export default Menu;
