import React from 'react';
import { Grid } from '@mui/material';

import NeedCard from './NeedCard';
import AddCardTemplate from '../AddCardTemplate';

const Need = () => (
  <Grid container spacing={2}>
    <Grid item>
      <NeedCard />
    </Grid>
    <Grid item>
      <AddCardTemplate height={235} pathTo="/AddNeed" />
    </Grid>
  </Grid>
);

export default Need;
