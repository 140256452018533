import sayBase from '../apis/sayBase';
import {
  FETCH_NGOS_REQUEST,
  FETCH_NGOS_SUCCESS,
  FETCH_NGOS_FAIL,
} from '../constants/ngoConstants';

export const fetchNgos = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_NGOS_REQUEST });
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await sayBase.get('/ngo/all', { config });

    dispatch({
      type: FETCH_NGOS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_NGOS_FAIL,
      payload: e.response && e.response.status ? e.response : e.message,
    });
  }
};
