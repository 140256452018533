import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import MediaSection from './MediaSection';
import InfoSection from './InfoSection';
import StorySection from './StorySection';
import DefaultButton from '../DefaultButton';
import Need from '../Need';

const PREFIX = 'ChildPage';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    padding: 12,
    width: '100%',
  },
});

const ChildPage = () => (
  <Root className={classes.root}>
    <Grid container direction="column" spacing={3}>
      <Grid container item justifyContent="space-between">
        <Typography variant="h2">Hello miss loblob</Typography>
        <DefaultButton text="Edit" size="small" />
      </Grid>
      <Grid container item justifyContent="space-between" spacing={2}>
        <MediaSection />
      </Grid>
      <Grid container item justifyContent="space-between" spacing={2}>
        <InfoSection />
      </Grid>
      <Grid container item justifyContent="space-between" spacing={2}>
        <StorySection />
      </Grid>
      <Grid container item justifyContent="space-between">
        <Typography variant="h2">Child Needs</Typography>
      </Grid>
      <Grid item container>
        <Need />
      </Grid>
    </Grid>
  </Root>
);

export default ChildPage;
