import React from 'react';
import { Grid } from '@mui/material';

import ChildCard from './ChildCard';
import AddCardTemplate from '../AddCardTemplate';

const Child = () => (
  <Grid container spacing={2}>
    <Grid item>
      <ChildCard />
    </Grid>
    <Grid item>
      <AddCardTemplate height={275} pathTo="/AddChild" />
    </Grid>
  </Grid>
);

export default Child;
