import axios from 'axios';
import apiUrl, { baseUrl } from '../env';

export default axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: '',
    'Access-Control-Allow-Origin': baseUrl,
    'Cache-Control': 'no-cache',
  },
});
